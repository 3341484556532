<template>
  <section class="html-block constrain">
    <div class="w-full" v-html="payload.html" />
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>
